import { Result, getParser } from '@breathelife/result';
import * as z from 'zod';

import { KeepTypeAlias } from '../utilities';
import { entitySchema } from './entities';
import { entityMappingCombination } from './schemas/mappingsPerNamespace';

export const baseEntityMapping = z.intersection(
  z.object({
    customCode: z.string().min(1, { message: 'Your code is empty' }),
    questionnaireVersionId: z.string().uuid(),
  }),
  entityMappingCombination,
);

export const entityMappingSchema = z.intersection(z.object({ id: z.string().uuid() }), baseEntityMapping);

export const entityMappingWithOptionalIdSchema = z.intersection(
  z.object({ id: z.string().uuid().optional() }),
  baseEntityMapping,
);

export const entityMappingsArraySchema = z.array(entityMappingSchema);

export type EntityMapping = KeepTypeAlias<z.infer<typeof entityMappingSchema>>;

export const entityMappingWithEntitiesSchema = z.intersection(
  z.object({
    entities: z.array(entitySchema).optional(),
  }),
  entityMappingSchema,
);

export type EntityMappingWithEntities = KeepTypeAlias<z.infer<typeof entityMappingWithEntitiesSchema>>;

export const entityMappingsWithEntitiesSchema = z.array(
  z.intersection(
    entityMappingSchema,
    z.object({
      entities: z.array(entitySchema),
    }),
  ),
);

export type EntityMappingsWithEntities = z.infer<typeof entityMappingsWithEntitiesSchema>;

export const entityMappingWithEntityIdsSchema = z.intersection(
  z.object({
    entities: z.array(z.string().uuid()).optional(),
  }),
  entityMappingSchema,
);

export type EntityMappingWithEntityIds = z.infer<typeof entityMappingWithEntityIdsSchema>;

export const entityMappingSearchParams = z.intersection(
  z.object({
    questionnaireVersionId: z.string().uuid(),
  }),
  entityMappingCombination,
);

export type GetEntityMappingParams = z.infer<typeof entityMappingSearchParams>;

export const getEntityMappingReturnValueSchema = z.object({
  entityMapping: z.union([entityMappingSchema, entityMappingWithEntitiesSchema]),
  typeDefinitions: z.string().optional(),
});

export type GetEntityMappingReturnValue = z.infer<typeof getEntityMappingReturnValueSchema>;

export function createEntityMapping(unknown: unknown): Result<string, EntityMapping> {
  return getParser(entityMappingSchema)(unknown).mapError((error) => error.message);
}

export const entityMappingDependenciesSchema = z.object({
  entityMappingId: z.string().uuid(),
  entityId: z.string().uuid(),
});

export type EntityMappingDependencies = KeepTypeAlias<z.infer<typeof entityMappingDependenciesSchema>>;
