import * as z from 'zod';

import { Language } from '../../../../localization';
import { PdfDocumentType } from '../../../../carrierQuestionnaire';

const headerTextSchema = z.object({
  insuranceApplication: z.string().optional(),
  referenceNumber: z.string().optional(),
  additionalText: z.string().optional(),
});

export type HeaderText = z.infer<typeof headerTextSchema>;

const footerTextSchema = z.object({
  summaryFor: z.string(),
  additionalText: z.string().optional(),
});

export type FooterText = z.infer<typeof footerTextSchema>;

const pdfHeaderDataSchema = z.object({
  logo: z.object({
    alt: z.string(),
    base64ImageRepresentation: z.string(),
    height: z.string().optional(),
    width: z.string().optional(),
    url: z.string().optional(),
  }),
  title: z.string(),
  productInfo: z.string(),
  referenceNumber: z.string(),
  carrierName: z.string(),
  customHeaderText: z
    .object({
      [Language.en]: z
        .object({
          [PdfDocumentType.advisorsReport]: headerTextSchema,
          [PdfDocumentType.application]: headerTextSchema,
          [PdfDocumentType.carrierReport]: headerTextSchema,
          [PdfDocumentType.oneSpanEvidenceSummary]: headerTextSchema,
          [PdfDocumentType.external]: headerTextSchema,
        })
        .partial(),
      [Language.fr]: z
        .object({
          [PdfDocumentType.advisorsReport]: headerTextSchema,
          [PdfDocumentType.application]: headerTextSchema,
          [PdfDocumentType.carrierReport]: headerTextSchema,
          [PdfDocumentType.oneSpanEvidenceSummary]: headerTextSchema,
          [PdfDocumentType.external]: headerTextSchema,
        })
        .partial(),
    })
    .partial()
    .optional(),
  additionalHeaderText: z.string().optional(),
});

export type PdfHeaderData = z.infer<typeof pdfHeaderDataSchema>;

const pdfFooterDataSchema = z.object({
  referenceNumber: z.string(),
  fullName: z.string(),
  carrierName: z.string(),
  customFooterText: z
    .object({
      [Language.en]: z
        .object({
          [PdfDocumentType.advisorsReport]: footerTextSchema,
          [PdfDocumentType.application]: footerTextSchema,
          [PdfDocumentType.carrierReport]: footerTextSchema,
          [PdfDocumentType.oneSpanEvidenceSummary]: footerTextSchema,
          [PdfDocumentType.external]: footerTextSchema,
        })
        .partial(),
      [Language.fr]: z
        .object({
          [PdfDocumentType.advisorsReport]: footerTextSchema,
          [PdfDocumentType.application]: footerTextSchema,
          [PdfDocumentType.carrierReport]: footerTextSchema,
          [PdfDocumentType.oneSpanEvidenceSummary]: footerTextSchema,
          [PdfDocumentType.external]: footerTextSchema,
        })
        .partial(),
    })
    .partial()
    .optional(),
  additionalFooterText: z.string().optional(),
});

export type PdfFooterData = z.infer<typeof pdfFooterDataSchema>;

export const generatePDFHeaderAndFooterSchema = z.object({
  // @ts-ignore
  headerData: pdfHeaderDataSchema.deepPartial(),
  // @ts-ignore
  footerData: pdfFooterDataSchema.deepPartial(),
});

export type PdfGenerationOverrides = z.infer<typeof generatePDFHeaderAndFooterSchema>;
