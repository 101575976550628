export * from './mappings';
export * from './mappingsPerNamespace';

export {
  entityMappingsFeatureConfigSchema,
  entityMappingsFeatureConfigJSONSchema,
  EntityMappingsConfig,
  AvailableEntityMappings,
  InvalidEntityMappingFeatureConfig,
} from './config';
