import * as z from 'zod';

export const stripeTransactionSchema = z.object({
  id: z.string(),
  setupIntentId: z.string(),
  subscriptionId: z.string().optional(),
  paymentTransactionId: z.string().optional(),
});

export type StripeTransaction = z.infer<typeof stripeTransactionSchema>;
