import * as z from 'zod';
import zodToJsonSchema from 'zod-to-json-schema';
import { Mappings, Namespaces } from './mappingsPerNamespace';

const mappingsConfigSchema = z
  .object({
    [Namespaces.Documents]: z.array(z.literal(Mappings.GeneratePDFHeaderAndFooter)),
    [Namespaces.ESign]: z.array(z.literal(Mappings.GetSignerRules)),
    [Namespaces.Underwriting]: z.array(z.literal(Mappings.GetBLMIBProviderInquiries)),
    [Namespaces.PolicyAdminSystemIntegration]: z.array(z.literal(Mappings.IngeniumPayload)),
  })
  .partial();

export class InvalidEntityMappingFeatureConfig extends Error {
  readonly tag = 'InvalidEntityMappingFeatureConfig';
}

export const entityMappingsFeatureConfigSchema = z.union([
  z.object({
    enabled: z.literal(false),
  }),
  z.object({
    enabled: z.literal(true),
    mappings: mappingsConfigSchema,
    carrierDefinedEntities: z.object({ enabled: z.boolean() }),
  }),
]);

export type EntityMappingsConfig = z.infer<typeof entityMappingsFeatureConfigSchema>;
export type AvailableEntityMappings = z.infer<typeof mappingsConfigSchema>;

export const entityMappingsFeatureConfigJSONSchema = zodToJsonSchema(
  entityMappingsFeatureConfigSchema,
  'entityMappingsFeatureConfigJSONSchema',
);
