export enum DateTimeReference {
  currentDateTime = 'current_date_time',
}

export enum AgeRoundingType {
  lastBirthday = 'lastBirthday', // Being 30.1 years old = rounded to 30
  nextBirthday = 'nextBirthday', // Being 30.1 years old = rounded to 31
  closestBirthday = 'closestBirthday', // Being 30.1 years old = rounded to 30
  none = 'none',
}

export enum AgeFactor {
  minor = 'minor',
}

export enum TimeUnitRoundingType {
  none = 'none', // Count from 0, you are 0 days old at birth
  nextUnit = 'nextUnit', // Count from 1, you are 1 day old at birth
}

export type TimeRoundingType = AgeRoundingType | TimeUnitRoundingType;

export enum DateUnit {
  day = 'day',
  week = 'week',
  month = 'month',
  year = 'year',
  hour = 'hour',
  minute = 'minute',
  second = 'second',
  millisecond = 'millisecond',
}

export enum ShiftMagnitude {
  earliestDateRespectingShiftValue = 'earliestDateRespectingShiftValue',
  latestDateRespectingShiftValue = 'latestDateRespectingShiftValue',
}
