import { z } from 'zod';
import { entitySchema } from './entities';
import { KeepTypeAlias } from '../utilities';

export type CreateEntityData = z.infer<typeof createEntitySchema>;
export const createEntitySchema = entitySchema.pick({ name: true, questionnaireVersionId: true });

export type UpdateEntityData = z.infer<typeof updateEntitySchema>;
export const updateEntitySchema = entitySchema.pick({ name: true, questionnaireVersionId: true, schema: true });

export type FindEntityQuery = z.infer<typeof findEntitySchema>;
export const findEntitySchema = entitySchema.pick({ questionnaireVersionId: true });

export const testEntityMappingQueryParametersSchema = z.object({
  applicationId: z.string(),
  entityName: z.string(),
  verifyOutputType: z.literal('false').optional(),
});

export type TestEntityMappingQueryParameters = KeepTypeAlias<z.infer<typeof testEntityMappingQueryParametersSchema>>;
