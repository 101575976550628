import { Result, getParser } from '@breathelife/result';
import * as z from 'zod';
import { KeepTypeAlias } from '../utilities';

export const entitySchema = z.object({
  id: z.string().uuid(),
  questionnaireVersionId: z.string().uuid(),
  schema: z.string(),
  name: z.string(),
  generatedType: z.string(),
});

export const entitiesArraySchema = z.array(entitySchema);

export type Entity = KeepTypeAlias<z.infer<typeof entitySchema>>;

export function createEntity(unknown: unknown): Result<string, Entity> {
  return getParser(entitySchema)(unknown).mapError((error) => error.message);
}
