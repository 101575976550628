import * as z from 'zod';
import { KeepTypeAlias, keysOf } from '../../utilities';

export const Namespaces = {
  Documents: 'Documents',
  ESign: 'ESign',
  Underwriting: 'Underwriting',
  CarrierDefined: 'CarrierDefined',
  PolicyAdminSystemIntegration: 'PolicyAdminSystemIntegration',
} as const;

export type Namespace = keyof typeof Namespaces;

export const namespace = (namespace: unknown): Namespace | null => {
  if (typeof namespace !== 'string') {
    return null;
  }

  return keysOf(Namespaces).find((e) => e === namespace) || null;
};

export const Mappings = {
  GetSignerRules: 'GetSignerRules',
  GeneratePDFHeaderAndFooter: 'GeneratePDFHeaderAndFooter',
  GetBLMIBProviderInquiries: 'GetBLMIBProviderInquiries',
  IngeniumPayload: 'IngeniumPayload',
} as const;

export type Mapping = keyof typeof Mappings;

const carrierDefinedMappingCombinationSchema = z.object({
  namespace: z.literal(Namespaces.CarrierDefined),
  mapping: z.string(),
});

export type CarrierDefinedMappingCombination = KeepTypeAlias<z.infer<typeof carrierDefinedMappingCombinationSchema>>;

export const entityMappingCombination = z.union([
  z.object({ namespace: z.literal(Namespaces.ESign), mapping: z.literal(Mappings.GetSignerRules) }),
  z.object({ namespace: z.literal(Namespaces.Documents), mapping: z.literal(Mappings.GeneratePDFHeaderAndFooter) }),
  z.object({ namespace: z.literal(Namespaces.Underwriting), mapping: z.literal(Mappings.GetBLMIBProviderInquiries) }),
  z.object({
    namespace: z.literal(Namespaces.PolicyAdminSystemIntegration),
    mapping: z.literal(Mappings.IngeniumPayload),
  }),
  carrierDefinedMappingCombinationSchema,
]);

export class EntityMappingCombinationCreationError extends Error {
  tag = 'EntityMappingCombinationCreationError';
}

export type EntityMappingCombination = KeepTypeAlias<z.infer<typeof entityMappingCombination>>;
