import { CarrierSubmissionStatus } from '../applications';
import { ESignCeremony, ESignProviderPackage, ESignSigner } from '../esign';
import { Application, StoredFile, LeadStatus, Lead } from '../models';
import { DecisionsPerProposedInsured } from '../pointOfSaleDecision';
import { createFindQueryParams } from './requests';

export type ApplicationSupportApplication = Pick<Application, 'id' | 'refNo' | 'createdAt' | 'updatedAt'> & {
  insuredCount?: number;
  leadStatus?: LeadStatus;
  carrierSubmissionStatus?: CarrierSubmissionStatus | null;
};

type LeadFilters = {
  [K in keyof Lead]?: Lead[K] | '';
};

export type ApplicationFilters = {
  [K in keyof Omit<Application, 'lead'> | 'lead']?: K extends 'lead' ? LeadFilters : Application[K] | '';
};

export enum ApplicationSearchMode {
  application = 'application',
  esign = 'esign',
}

export type ApplicationSupportApplicationOverview = ApplicationSupportApplication & {
  assignedToEmail?: string;
  submitted: boolean;
  outcomes: DecisionsPerProposedInsured;
};

export type ApplicationSupportESignatureFile = Pick<
  StoredFile,
  'id' | 'displayName' | 'docType' | 'createdAt' | 'updatedAt'
>;

export type ApplicationSupportESignaturePackage = ESignProviderPackage;

export type ApplicationSupportESignatureParticipant = Pick<ESignSigner, 'id' | 'email' | 'roles' | 'status'>;

export type ApplicationSupportESignatureCeremony = Pick<ESignCeremony, 'id' | 'status' | 'createdAt' | 'updatedAt'> & {
  provider: string;
  files: Partial<ApplicationSupportESignatureFile>[];
  participants: ApplicationSupportESignatureParticipant[];
  providerPackage?: ApplicationSupportESignaturePackage;
};

export type ApplicationSupportAssociatedFile = {
  id: string;
  fileType: string;
  fileName: string;
  fileSize: string;
  updatedAt: Date;
  createdAt: Date;
  mimeType: string;
};

type ApplicationSupportApplicationProps = keyof ApplicationSupportApplication;

const isValidFields = (fields: string): boolean => {
  const fieldsArray = fields.split(',');

  const allowedProps: ApplicationSupportApplicationProps[] = ['id', 'refNo', 'createdAt', 'updatedAt'];

  return fieldsArray.every((field) => allowedProps.includes(field as ApplicationSupportApplicationProps));
};

export const applicationSupportFindQueryParams = createFindQueryParams(isValidFields);

type ApplicationSupportFileApplicationProps = keyof ApplicationSupportAssociatedFile;

const isValidFileFields = (fields: string): boolean => {
  const fieldsArray = fields.split(',');

  const allowedProps: ApplicationSupportFileApplicationProps[] = [
    'id',
    'fileType',
    'fileName',
    'fileSize',
    'updatedAt',
    'createdAt',
    'mimeType',
  ];

  return fieldsArray.every((field) => allowedProps.includes(field as ApplicationSupportFileApplicationProps));
};

export const applicationSupportFileFindQueryParams = createFindQueryParams(isValidFileFields);
