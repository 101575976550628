import { z } from 'zod';
import { PaymentFrequency } from '../products/insuranceProductPricing';
import { collectionInstanceIdentifiersSchema } from '../carrierQuestionnaire';
import { KeepTypeAlias } from '../utilities';

export type QuotePerProduct = Record<string, number | null>;

export const quoteInfoSchema = z.object({
  quotePerProduct: z.record(z.number().nullable(), z.string()),
  paymentFrequency: z.nativeEnum(PaymentFrequency),
  annualPremium: z.number().optional().nullable(),
});

export type QuoteInfo = z.infer<typeof quoteInfoSchema>;

export type TotalPremiums = {
  monthly: number;
  annual: number;
};

export const nodeIdsAffectingPricingSchema = z.record(
  z.string(),
  z.object({
    nodeId: z.string(),
    collectionIdentifiers: collectionInstanceIdentifiersSchema,
  }),
);

export type NodeIdsAffectingPricing = KeepTypeAlias<z.infer<typeof nodeIdsAffectingPricingSchema>>;
