import { ParticipantRole } from '../participant';
import { PdfDocumentType } from '../carrierQuestionnaire/documentType';

export enum ESignSignerStatus {
  SIGNED = 'SIGNED',
  EMAIL_BOUNCED = 'EMAIL_BOUNCED',
  SIGNER_LOCKED_OUT = 'SIGNER_LOCKED_OUT',
  EXPIRED = 'EXPIRED',
  INTERNAL_SIGNING_EMAIL_SENT = 'INTERNAL_SIGNING_EMAIL_SENT',
}

export enum ESignerMaturityAgeByProvince {
  QC = 18,
  DEFAULT = 16,
}

export type BaseESignSigner = {
  status?: ESignSignerStatus;
  firstName: string;
  lastName: string;
  email: string;
  cellphone: string;
  roles: Omit<ParticipantRole, 'id'>[];
};

export type ESignSigner2FAInfo = BaseESignSigner & {
  partyId?: string;
  documentTypes: PdfDocumentType[];
  tag?: string;
};

export type ESignSigner = BaseESignSigner & { id: string; ceremonyId: string; tag: string };

export type ESignSignerWithSigningUrl = ESignSigner & { signingUrl: string };

export type DatabaseESignSigner = Omit<ESignSigner, 'roles'> & {
  roles: string[];
};

export enum EnforceUniqueEmailStrategy {
  ALL = 'ALL',
  ADVISORS_ONLY = 'ADVISORS_ONLY',
}
